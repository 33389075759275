
import DateTimePicker from "@evercam/shared/components/DateTimePicker"
import axios from "@evercam/shared/api/client/axios"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCompareStore } from "@/stores/compare"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"

export default {
  name: "CompareDateTimePicker",
  components: {
    DateTimePicker,
  },
  data() {
    return {
      isLoadingDays: false,
      availableDays: [],
      availableHours: [],
    }
  },
  computed: {
    timezone() {
      return this.cameraStore.selectedCameraTimezone
    },
    ...mapStores(useCompareStore, useSnapshotStore, useCameraStore),
    timestamp() {
      return this.$attrs.value
    },
    maxDate() {
      return this.$moment().tz(this.timezone)?.format("YYYY-MM-DD")
    },
    minDate() {
      return this.$moment(
        Math.min(
          this.$moment(
            this.snapshotStore.oldestSnapshotTimestamp(
              this.cameraStore.selectedCameraExid
            )
          ),
          this.$moment(this.cameraStore.selectedCamera?.createdAt)
        )
      ).toISOString()
    },
  },
  watch: {
    timestamp: {
      handler(value) {
        if (!value) {
          return
        }
        const selectedTimestamp = this.$moment.tz(value, this.timezone),
          selectedDay = selectedTimestamp.format("YYYY-MM-DD"),
          selectedMonth = selectedTimestamp.format("YYYY-MM")
        this.availableDays =
          this.compareStore.availableDaysByMonth[selectedMonth] || []
        this.availableHours =
          this.compareStore.availableHoursByDay[selectedDay] || []
      },
      immediate: true,
    },
  },
  methods: {
    async onMonthChange(month) {
      if (!month || this.isLoadingDays) {
        this.availableDays = []

        return
      }

      this.isLoadingDays = true
      await this.compareStore.fetchAvailableDaysForMonth(
        month,
        this.cameraStore.selectedCameraExid
      )
      this.availableDays = this.compareStore.availableDaysByMonth[month] || []
      this.isLoadingDays = false
    },
    async onDayChange(date) {
      if (!date) {
        this.availableHours = []

        return
      }
      await this.compareStore.fetchAvailableHoursForDay(
        date,
        this.cameraStore.selectedCameraExid
      )
      const selectedDay = this.$moment
        .tz(date, this.timezone)
        .format("YYYY-MM-DD")
      this.availableHours =
        this.compareStore.availableHoursByDay[selectedDay] || []
    },
    onHourChange() {
      axios.cancelRequests()
    },
  },
}
