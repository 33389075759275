
import CompareBox from "@/components/compare/CompareBox"
import CompareSidebar from "@/components/compare/CompareSidebar"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCompareStore } from "@/stores/compare"
import { useCameraStore } from "@/stores/camera"
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"
import { InfoPage } from "@evercam/shared/types"
import RightSidebarContent from "@/components/portals/RightSidebarContent"

export default {
  name: "Compare",
  components: {
    CompareBox,
    CompareSidebar,
    RightSidebarContent,
  },
  meta: {
    pageId: AnalyticsEventPageId.Compare,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, app }) {
    const cameraStore = useCameraStore()

    if (app.$permissions.project.has.go()) {
      redirect(`${cameraStore.cameraRoute}/info/${InfoPage.Compare}`)

      return
    }

    if (!app.$permissions.user.can.access.compare()) {
      return redirect(cameraStore.cameraRoute)
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.compare"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useCompareStore,
      useCameraStore,
      useLayoutStore
    ),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.layoutStore.enableRightSidebar()
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
    this.compareStore.$reset()
  },
}
